import styled from "styled-components"
import * as palette from "../../styles/variables"
import { Button } from "antd"

export const BannerSection = styled.section`
  background: ${palette.APP_BACKGROUND_COLOR};
  padding: 80px 60px 50px;
  height: calc(100vh - 105px);
  @media only screen and (max-width: 768px) {
    padding: 20px;
    height: auto;
    .mobMedialWrapper {
      position: unset;
      margin-top: 8px;
      padding: 0px;
      .mobMedialLinks {
        a {
          width: 28px;
          height: 28px;
        }
      }
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 992px) {
    padding: 60px 48px 50px;
  }
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    padding: 60px 48px 50px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
  }

  .banner-container {
    display: flex;
    justify-content: space-between;
    max-width: 1300px;
    margin: auto;
    position: relative;
    z-index: 1;
    @media only screen and (min-width: 1500px) {
      max-width: 1400px;
    }
    @media (max-width: 768px) {
      flex-direction: column;
      z-index: unset;
      .content {
        z-index: 1;
      }
      .ant-col {
        min-width: 100%;
      }
    }
    .banner-image-details {
      z-index: 0;
      .bannerbg {
        z-index: -1;
        position: absolute;
        top: -23%;
      }
      @media (max-width: 768px) {
        display: flex;
      }
    }
    .onboard-animations {
      position: absolute;
      top: 50px;
      right: 0px;
    }
  }

  .banner-content {
    .ant-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px !important;
      position: relative;
      overflow: hidden;
      z-index: 1;
      border-radius: 6px;
      color: ${palette.WHITE_COLOR} !important;
      .btnCont {
        max-width: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 32px;
        img {
          width: 1.8vw;
          height: 1.8vw;
          margin-left: 0.8vw;
        }
      }
      .arrow {
        margin-left: 0vw;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.6vw;
      }
    }
    @media only screen and (max-width: 768px) {
      padding-left: 0;
      max-width: 500px;
      margin: 0 auto;
      .btnCont img {
        width: 14px !important;
        height: 14px !important;
        margin-left: 6px !important;
      }
    }
    @media only screen and (min-width: 1300px) {
      margin-top: 8%;
    }
  }

  .content-title {
    margin: 8px 0px 4px;
    font-weight: 700;
    color: ${palette.WHITE_COLOR};
    font-family: ${palette.FONT_FAMILY};
    span {
      display: block;
      background: linear-gradient(
        163.32deg,
        #a8b7d3 9.11%,
        #b5c6e2 34.93%,
        #d3e0ef 57.41%,
        #e5effa 93.73%
      );
      -webkit-background-clip: text !important;
      -webkit-text-fill-color: transparent !important;
      background-clip: text !important;
      text-fill-color: transparent !important;
    }
    .topText {
      margin-bottom: 6px;
      display: inline-block;
    }
    @media only screen and (min-width: 768px) and (max-width: 992px) {
      font-size: 30px;
      line-height: 36px;
    }
    @media only screen and (max-width: 768px) {
      font-size: 20px;
      line-height: 32px;
      margin: 0px 0px 8px !important;
      text-align: center;
    }
    @media only screen and (max-width: 380px) {
      font-size: 18px;
      line-height: 8.5vw;
    }
  }

  .content-sub-title {
    font-weight: 600;
    font-size: 2.7vw;
    line-height: 3.2vw;
    font-family: ${palette.FONT_FAMILY};
    color: ${palette.BLACK_COLOR};
    @media only screen and (min-width: 768px) and (max-width: 992px) {
      font-size: 36px;
      line-height: 42px;
    }
    @media only screen and (max-width: 768px) {
      font-size: 26px;
      line-height: 34px;
      margin: 0px 0px 8px !important;
      text-align: center;
    }
  }

  .content-description {
    font-weight: normal;
    color: ${palette.STEEL_MEDIUM_LIGHT};
    margin: 20px 0px 28px;
    @media only screen and (max-width: 768px) {
      font-size: 12px;
      line-height: 18px;
      margin: 20px 0px 28px;
      text-align: center;
    }
  }

  .banner-appPage {
    padding-left: 25px;
    @media only screen and (max-width: 768px) {
      margin-bottom: 15px;
      margin-top: 0;
      padding-left: 0;
    }
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  .section-background {
    position: absolute;
    right: 0;
    top: -2%;
    z-index: -1;
    @media only screen and (min-width: 992px) and (max-width: 1200px) {
      right: -2%;
      top: -2%;
    }
    @media only screen and (min-width: 768px) and (max-width: 992px) {
      top: -3%;
    }
    @media only screen and (max-width: 768px) {
      display: none;
    }
    @media only screen and (min-width: 1500px) {
      top: 0;
    }
  }

  .circle {
    width: 10rem;
    height: 5rem;
    opacity: 50%;
    border-radius: 24.3513px;
    position: fixed;
    bottom: 0;
    filter: blur(65px);
    background: #00e8ec;
    top: 12%;
    left: 0;
    z-index: 23;
  }
  .circleBlue {
    width: 10rem;
    height: 7rem;
    opacity: 50%;
    border-radius: 24.3513px;
    position: fixed;
    bottom: 0px;
    filter: blur(90px);
    background: rgb(29, 0, 143);
    top: 20%;
    left: 14%;
  }

  .mediawrapper {
    z-index: 1;
    margin: 0;
    top: 32%;
    transform: translateY(-50%);
    bottom: unset;
    padding: 16px 8px 16px 8px;
    border-radius: 8px 0px 0px 8px;
    background: ${palette.BLACK_COLOR};
    box-shadow: 0px 0px 16px 0px #00c7b729;
    .medialinks {
      flex-direction: column;
    }
  }
  h2 {
    @media (max-width: 768px) {
      text-align: center;
    }
  }
`

export const ImageSec = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    max-width: 500px;
    display: inline-block;
    z-index: 2;
  }
  @media (max-width: 768px) {
    margin-bottom: 24px;
    span {
      max-width: 200px;
    }
  }
`

export const ContentCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start; // align content to the left on default

  @media (max-width: 768px) {
    // for smaller screens (you can adjust this breakpoint as needed)
    align-items: center; // align content to the center
  }
  h2 {
    padding-bottom: 30px;
  }
`

export const ImageCol = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  /* Default image size */
  img {
    width: 100%;
    height: auto;
  }

  /* Image size for larger screens, e.g. screens wider than 768px */
  @media (min-width: 768px) {
    img {
      width: 80%; // or whatever reduced size you prefer
      height: auto;
    }
  }

  /* Further breakpoints, if needed. For example, screens wider than 1200px */
  @media (min-width: 1200px) {
    img {
      width: 80%;
      height: auto;
    }
  }
`
export const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;

  // If there's specific spacing between the icon and the text
  & > *:first-child {
    // Targeting the icon, assuming it's the first child
    // margin-right: 10px;
  }
`
