// src/pages/employee-wellness.js

import React from "react"
import BookADemo from "../components/BookADemo"
import PersonalizedWorkouts from "../components/CorporateFeature/personalized-workouts"
import CustomizedMindFullness from "../components/CorporateFeature/customized-mindfullness"
import AdaptiveProgress from "../components/CorporateFeature/adaptive-progress"
import ContinuousInnovation from "../components/CorporateFeature/continuous-innovation"
import Trusted from "../components/Trusted/trusted"
import CorporateBanner from "../components/CorporateBanner/corporate-banner"

const EmployeeWellness = () => {
  return (
    <>
      <CorporateBanner />
      <Trusted />
      <PersonalizedWorkouts />
      <CustomizedMindFullness />
      <AdaptiveProgress />
      <ContinuousInnovation />
      <BookADemo />
    </>
  )
}

export default EmployeeWellness
