import React from "react"
import { ContentSection, ImageSec, SectionContainer } from "./styles"
import { Col, Row } from "antd"
import * as palette from "../../styles/variables"
import { StaticImage } from "gatsby-plugin-image"

export const ContinuousInnovation = () => {
  return (
    <SectionContainer style={{ background: palette.SECTION_BACKGROUND_COLOR }}>
      <Row
        className="workRow"
        style={{ alignItems: "center", flexDirection: "row-reverse" }}
      >
        <Col xs={24} sm={24} md={11} lg={11} xl={11}>
          <ImageSec>
            <span>
              <StaticImage
                src="../../images/Continues-Innovation.png"
                alt="Continuous Innovation"
                placeholder="none"
              />
            </span>
          </ImageSec>
        </Col>
        <Col xs={24} sm={24} md={13} lg={13} xl={13}>
          <ContentSection>
            <h3>Continuous Innovation</h3>
            <p className="description">
              AI evolves alongside user preferences, generating novel routines
              and experiences, keeping wellness programs fresh and motivating.
            </p>
          </ContentSection>
        </Col>
      </Row>
    </SectionContainer>
  )
}
export default ContinuousInnovation
