import styled from "styled-components"
import * as palette from "../../styles/variables"

export const SectionContainer = styled.section`
  padding: 100px 60px;
  background: ${palette.SECTION_BACKGROUND_COLOR};
  overflow: hidden;
  @media only screen and (max-width: 768px) {
    padding: 30px 20px 60px 20px;
  }
`
export const SectionHeader = styled.div`
  max-width: 55vw;
  margin: 0 auto;
  h2 {
    text-align: center;
    background: linear-gradient(
      163.32deg,
      #a8b7d3 9.11%,
      #b5c6e2 34.93%,
      #d3e0ef 57.41%,
      #e5effa 93.73%
    );
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    background-clip: text !important;
    text-fill-color: transparent !important;
  }
  @media screen and (min-width: 1200px) {
    h2 {
      padding-bottom: 60px;
    }
  }
  @media (max-width: 768px) {
    max-width: 100%;
    h2 {
      font-size: 15px;
    }
  }
`

export const Trustedby = styled.div`
  .listItems {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 28px;
    flex-wrap: wrap;
  }

  a {
    width: 300.66px;
    height: 100.44px;
  }

  @media (max-width: 768px) {
    .listItems {
      gap: 12px;
    }
    a {
      width: 150px;
      height: 50px;
      max-width: 312px;
    }
  }
  // @media only screen and (max-width: 768px) {
  //   .listItems {
  //     margin-top: 16px;
  //     gap: 20px;
  //     justify-content: center;
  //     flex-wrap: wrap;
  //     align-items: center;
  //     a {
  //       width: 26%;
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;
  //     }
  //   }
  // }
`

// export const AndManyMore = styled.div`
//   max-width: 55vw;
//   margin: 0 auto;

//   p {
//     text-align: center;
//     background: linear-gradient(
//       163.32deg,
//       #a8b7d3 9.11%,
//       #b5c6e2 34.93%,
//       #d3e0ef 57.41%,
//       #e5effa 93.73%
//     );
//     -webkit-background-clip: text !important;
//     -webkit-text-fill-color: transparent !important;
//     background-clip: text !important;
//     text-fill-color: transparent !important;
//   }

//   @media screen and (min-width: 1200px) {
//     p {
//       padding-top: 60px;
//     }
//   }

//   @media (max-width: 768px) {
//     max-width: 100%;

//     p {
//       font-size: 8px; /* Adjust this value as per your requirements */
//       padding-top: 30px;
//     }
//   }
// `
