import React from "react"
import {
  SectionContainer,
  SectionHeader,
  Trustedby,
  AndManyMore,
} from "./styles"
import { StaticImage } from "gatsby-plugin-image"

export const Trusted = () => {
  return (
    <SectionContainer>
      <SectionHeader>
        <h2>Trusted by popular brands</h2>
      </SectionHeader>
      <Trustedby>
        <div className="listItems">
          <a
            href="https://botvfx.com"
            target="_blank"
            without="true"
            rel="noopener noreferrer"
          >
            <StaticImage
              src="../../images/botvfxlogo.png"
              alt="Botvfx"
              placeholder="none"
            />
          </a>
          <a
            href="https://www.purplequarter.com"
            target="_blank"
            without="true"
            rel="noopener noreferrer"
          >
            <StaticImage
              src="../../images/purplequarter.png"
              alt="Purplequarter"
              placeholder="none"
            />
          </a>
          <a
            href="https://www.advantageclub.co/in"
            target="_blank"
            without="true"
            rel="noopener noreferrer"
          >
            <StaticImage
              src="../../images/advantage.png"
              alt="Purplequarter"
              placeholder="none"
            />
          </a>
          <a
            href="https://www.sixalpha.in/"
            target="_blank"
            without="true"
            rel="noopener noreferrer"
          >
            <StaticImage
              src="../../images/six_alpha_logo.png"
              alt="Purplequarter"
              placeholder="none"
            />
          </a>
          {/* <a
            href="https://spyn.ai"
            target="_blank"
            without="true"
            rel="noopener noreferrer"
          >
            <StaticImage
              src="../../images/spynlogo.png"
              alt="SPYN"
              placeholder="none"
            />
          </a>
          <a
            href="https://www.businessinsider.in"
            target="_blank"
            without="true"
            rel="noopener noreferrer"
          >
            <StaticImage
              src="../../images/businessinsiderslogo.png"
              alt="Businessinsider"
              placeholder="none"
            />
          </a> */}
        </div>
        {/* <AndManyMore>
          <p>and many more...</p>
        </AndManyMore> */}
      </Trustedby>
    </SectionContainer>
  )
}

export default Trusted
