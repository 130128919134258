import React from "react"
import { ContentSection, ImageSec, SectionContainer } from "./styles"
import { Col, Row } from "antd"
import { StaticImage } from "gatsby-plugin-image"

export const AdaptiveProgress = () => {
  return (
    <SectionContainer>
      <Row className="workRow" style={{ alignItems: "center" }}>
        <Col xs={24} sm={24} md={11} lg={11} xl={11}>
          <ImageSec>
            <span>
              <StaticImage
                src="../../images/Adaptive-Progress.png"
                alt="Adapative Progress Tracking"
                placeholder="none"
              />
            </span>
          </ImageSec>
        </Col>
        <Col xs={24} sm={24} md={13} lg={13} xl={13}>
          <ContentSection>
            <h3>Adaptive Progress Tracking</h3>
            <p className="description">
              AI-driven algorithms analyze data over time, providing insights
              into progress and suggesting adjustments for optimal outcomes.
            </p>
          </ContentSection>
        </Col>
      </Row>
    </SectionContainer>
  )
}
export default AdaptiveProgress
