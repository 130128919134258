import React from "react"
import { ContentSection, ImageSec, SectionContainer } from "./styles"
import { Col, Row } from "antd"
import { StaticImage } from "gatsby-plugin-image"

export const PersonalizedWorkouts = () => {
  return (
    <SectionContainer>
      <Row className="workRow" style={{ alignItems: "center" }}>
        <Col xs={24} sm={24} md={11} lg={11} xl={11}>
          <ImageSec>
            <span>
              <StaticImage
                src="../../images/personalized-workouts.png"
                alt="Personalized Workouts"
                placeholder="none"
              />
            </span>
          </ImageSec>
        </Col>
        <Col xs={24} sm={24} md={13} lg={13} xl={13}>
          <ContentSection>
            <h3>Personalized Workouts</h3>
            <p className="description">
              Generative AI tailors workouts to individual needs, considering
              fitness levels, goals, and limitations, enhancing engagement and
              results.
            </p>
          </ContentSection>
        </Col>
      </Row>
    </SectionContainer>
  )
}
export default PersonalizedWorkouts
