import React from "react"
import { Button, Row, Col } from "antd"
import {
  BannerSection,
  ImageSec,
  ContentCol,
  ImageCol,
  StyledButton,
} from "./styles"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

export const CorporateBanner = () => {
  return (
    <BannerSection id="homeBanner">
      <Row className="banner-container" type="flex">
        <ContentCol xs={24} sm={24} md={11} lg={11} xl={11} className="content">
          <h2 className="content-title">
            <span>
              Reinforce your company <br />
              culture & values through <br />
              customized AI Coaches
            </span>
          </h2>
          <Link to="#bookADemoContainer">
            <StyledButton
              type="primary"
              htmlType="submit"
              icon={
                <StaticImage
                  src="../../images/sparkle.webp"
                  alt="BookaDemo"
                  placeholder="none"
                />
              }
            >
              Book a Demo
            </StyledButton>
          </Link>
        </ContentCol>
        <ImageCol xs={24} sm={24} md={11} lg={11} xl={11}>
          <ImageSec>
            <span>
              <StaticImage
                src="../../images/Corporate-banner.png"
                alt="Corporate Banner"
                placeholder="none"
              />
            </span>
          </ImageSec>
        </ImageCol>
      </Row>
    </BannerSection>
  )
}

export default CorporateBanner
